document.addEventListener( "DOMContentLoaded", () => {
	const menu = new Mmenu( ".mobile-navigation-menu", {
		extensions: [
			"position-back",
			"position-right",
		],
		navbars: [],
		screenReader: {
			aria: true,
			text: true,
		},
	} )

	$(".menu-button").click(function() {
		if ($(".mobile-navigation-menu").hasClass("mm-opened")) {
			menu.API.close()
		}
		else {
			menu.API.open()
		}
	});

	window.addEventListener("scroll", () => {
		let body = document.querySelector("body");
		let nav = document.getElementById("main-nav");

		body.classList.toggle("translucent-bg", window.scrollY > 50);

		if (window.scrollY > 50) {
			nav.style.height = "75px";
		}
		else {
			nav.style.height = "130px";
		}
	});

	if (document.getElementById("partners_module") != null) {
		let portfolioTables = document.getElementsByTagName("table");

		for (let i = 0; i < portfolioTables.length; i++) {
			portfolioTables[i].classList.add("responsive");
		}
	}
} )
